import TopNav from '@/components/TopNav'
import { BaseContainer } from '@/components/containers/BaseContainer'
import GoFavoritesModal from '@/components/modals/GoFavoritesModal'
import { useLocalStorage } from '@grandstand-web/bally-web-shared/src/hooks/useLocalStorage'
import { useWebWatchPage } from '@grandstand-web/bally-web-shared/src/hooks/useWebWatchPage'
import { UserServiceContext } from '@grandstand-web/bally-web-shared/src/services/user/UserService'
import { useContext, useEffect, useState } from 'react'
// Fake Watch Page data
import { ErrorPageView } from '@/components/ErrorPageView'
import { ComponentRenderer } from '@/components/grandstand/ComponentRenderer'
import { Loading } from '@/components/loading'
export default function Home() {
  const { isInMarket, isLoggedIn } = useContext(UserServiceContext)

  // Set to true only if the user is logged in and the modal hasn't been shown before
  const [hasShownModal, setHasShownModal] = useLocalStorage('favoritesModalShown', false)
  const [showModal, setShowModal] = useState(false)

  const { data: webWatchPage, error: webWatchPageError } = useWebWatchPage()

  useEffect(() => {
    if (isLoggedIn && hasShownModal === false) {
      const timer = setTimeout(() => {
        setShowModal(true)
      }, 5000) // Show after 5 seconds

      return () => {
        clearTimeout(timer) // Clear the timer if the component is unmounted
      }
    }
  }, [isLoggedIn, hasShownModal])

  const handleCloseModal = () => {
    setShowModal(false)
    setHasShownModal(true)
  }

  return (
    <>
      <BaseContainer>
        <TopNav />
      </BaseContainer>

      <BaseContainer fluid={true}>
        {webWatchPage === undefined ? (
          <Loading />
        ) : !isInMarket ? null : (
          <ComponentRenderer component={webWatchPage}></ComponentRenderer>
        )}
        {webWatchPageError && (
          <ErrorPageView
            labels={{
              title: 'Oh no!',
              subtitle: 'There was a problem loading the home page.',
              body: 'Please reload the page to try again',
              back: 'Reload',
            }}
            onBackClick={() => {
              window.location.reload()
            }}
          ></ErrorPageView>
        )}
        {showModal && <GoFavoritesModal onClose={handleCloseModal} />}
      </BaseContainer>
    </>
  )
}
